import { types } from '../actions/PurchaseActions';

const initialState = {
  screening: { personalAccountUsage: null, foreignTaxResident: null },
  details: {
    FirstName: null,
    LastName: null,
    DateOfBirth: null,
    Address: {
      AddressLine: null,
      AddressLine2: null,
      City: null,
      Province: null,
      PostalCode: null,
    },
    PhoneNumber: null,
    CASL: null,
    Occupation: null,
  },
};

function PurchaseReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_SCREENING_PERSONAL_ACCOUNT_USAGE:
      return {
        ...state,
        screening: {
          ...state.screening,
          personalAccountUsage: action.payload,
        },
      };
    case types.SET_USER_SCREENING_FOREIGN_TAX_RESIDENT:
      return {
        ...state,
        screening: {
          ...state.screening,
          foreignTaxResident: action.payload,
        },
      };
    case types.SET_USER_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case types.CLEAR_USER_DETAILS:
    case types.CLEAR_SESSION:
      return initialState;
    default:
      return state;
  }
}

export default PurchaseReducer;
