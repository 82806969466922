import React from 'react';
import { translate, t } from 'react-switch-lang';
import { moveFocusTo } from '../utils/Accessibility';
import { useRouter } from './Router';

const SkipToNav = () => {
  const router = useRouter();
  const { pathname } = router;
  const skipToMain = () => moveFocusTo('main');
  const skipToFooter = () => moveFocusTo('footer');
  const skipToCardMain = () => moveFocusTo('card-main');
  const skipToSidebar = () => moveFocusTo('sidebar');

  return (
    <SkipCont>
      { (pathname === '/[lang]/my-card') ?
        (
          <>
            <SkipToButton onClick={skipToCardMain} textKey="ButtonDescription_SkipToMain" />
            <SkipToButton onClick={skipToSidebar} textKey="ButtonDescription_SkipToSidebar" />
            <SkipToButton onClick={skipToFooter} textKey="ButtonDescription_SkipToFooter" id="cardFooterSkipLink" />
          </>
        ) : (
          <>
            <SkipToButton onClick={skipToMain} textKey="ButtonDescription_SkipToMain" />
            <SkipToButton onClick={skipToFooter} textKey="ButtonDescription_SkipToFooter" />
          </>
        )}
    </SkipCont>
  );
};

export const SkipToButton = ({ textKey, onClick, id, ...args }) => (
  <div id={id} className="skipSubCont">
    <button type="button" onClick={onClick} className="skipLink" {...args}>
      {t(textKey)}
    </button>
  </div>
);

export const SkipCont = ({ children, ...args }) => (
  <nav className="skipCont" {...args}>
    <div className="ui menu center skipLinkCont">
      <div className="ui menu center loadSkipLinkCont">{children}</div>
    </div>
  </nav>
);

export default translate(SkipToNav);
