import { useEffect } from 'react';
import { t } from 'react-switch-lang';
import MainLayout from '../components/MainLayout';
import Button from '../components/MapleButton';
import { useRouter, checkSession } from '../components/Router';
import { events, logAmpEvent } from '../utils/Amplitude';

function Custom404() {
  const router = useRouter();

  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_ERROR_PAGE, {
      Error: '404',
      URL: router.asPath,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainLayout title={t('PageTitle_404')}>
      <div className="errorPageContainer">
        <h1>{t('Error404_Title')}</h1>
        <p>{t('Error404_Subtitle')}</p>
        <Button
          text={t('Error404_Btn')}
          ampEvent={events.USER_CLICKED_ERROR_PAGE_BUTTON}
          ampEventProps={{
            Error: '404',
            URL: router.asPath,
          }}
          onClickEvent={() => {
            router.push('/');
          }}
        />
      </div>
    </MainLayout>
  );
}

export default checkSession(Custom404);
