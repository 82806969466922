import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { t, getLanguage } from 'react-switch-lang';
import { useRouter } from 'next/router';
import { captureException, captureMessage } from '@sentry/nextjs';

import { CARD_API, KYC_API, USER_API, CARD_INFO, PAYMENT_API } from './Constants';
import { setSession, clearSession } from '../redux/actions/AuthActions';
import { isDevelopment, isProduction } from './HostingEnv';
import { events, logAmpEvent } from './Amplitude';
import { mapPath } from './FrenchUrlMapping';

const Pool = new CognitoUserPool({
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
});

function log(...args) {
  // eslint-disable-next-line no-console
  if (isDevelopment) console.log(...args);
}

function warn(...args) {
  // eslint-disable-next-line no-console
  if (isDevelopment) console.warn(...args);
}

function cognitoLog(callName, data, success = true) {
  if (success) {
    log(`Cognito: %c${callName}`, 'background-color: plum;color:black;', data);
  } else {
    warn(`Cognito: %c${callName}`, 'background-color: plum;color:black;', data);
  }
}

function apiLog(callName, data) {
  log(`API: %c${callName}`, 'background-color: greenyellow;color:black;', data);
}

function callbackWrapper(callName, resolve, reject) {
  return (err, result) => {
    if (err) {
      cognitoLog(callName, err, false);
      reject(err);
    } else {
      cognitoLog(callName, result);
      resolve(result);
    }
  };
}

// custom error handler for maintenance mode api calls
export function handleMaintenaceModeError(call, res, errMsgCallback) {
  if (res.Result === 0 || res.Result === 42) return true; // successful
  errMsgCallback?.(res.Description || t('Error_Default'));
  return false;
}

export async function getSessionAndAttr(lang) {
  return new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(callbackWrapper('getSession', (session) => {
        user.getUserAttributes(callbackWrapper('getUserAttributes', (attributes) => {
          // Update language user attribute and
          // Refresh session if language in user attributes does not match client language
          const currLang = attributes.find((attr) => attr.Name === 'custom:language')?.Value;
          if (currLang !== lang) {
            const customAttribute = new CognitoUserAttribute({
              Name: 'custom:language',
              Value: lang,
            });
            user.updateAttributes([customAttribute], callbackWrapper('updateAttributes', () => {
              user.refreshSession(session.getRefreshToken(), callbackWrapper('refreshSession', (sess) => {
                resolve([sess, attributes]);
              }, reject));
            }, reject));
          } else {
            resolve([session, attributes]);
          }
        }, reject));
      }, reject));
    } else {
      reject();
    }
  });
}

export function useCognito() {
  const [user, setUser] = useState(Pool.getCurrentUser());
  const session = useSelector((state) => state.auth.session);
  const dispatch = useDispatch();
  const router = useRouter();
  const lang = getLanguage();

  // General API Fetch Call
  async function makeCall(call, data = {}) {
    const reqBody = { ...data, Platform: 'Web' };
    const url = process.env.NEXT_PUBLIC_API_URI + call;
    let apiCallSeq;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: session.getIdToken().getJwtToken(),
        'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
      },
      body: JSON.stringify(reqBody),
    }).then(async (res) => {
      const respBody = await res.json();
      apiCallSeq = res.headers.get('APICALL_SEQ');

      apiLog(call, { url, reqBody, respBody, respStatus: res.status });

      // expired token
      if (res.status === 401 && respBody.message === 'The incoming token has expired') {
        return (new Promise((resolve, reject) => {
          user.refreshSession(session.getRefreshToken(), (err, sess) => {
            if (err) {
              cognitoLog('refreshSession', err, false);
              reject(err);
            } else {
              cognitoLog('refreshSession', sess);
              dispatch(setSession(sess));

              fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: sess.getIdToken().getJwtToken(),
                  'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
                },
                body: JSON.stringify(reqBody),
              }).then(async (res2) => {
                apiCallSeq = res2.headers.get('APICALL_SEQ');
                const res2Body = await res2.json();
                apiLog(call, { url, reqBody, respBody: res2Body, respStatus: res2.status });
                resolve(res2Body);
              }).catch(() => reject());
            }
          });
        }));
      }

      if (res.status !== 200) {
        const err = new Error(`${JSON.stringify(respBody)}`);
        err.name = res.status;
        throw err;
      }

      return respBody;
    }).catch((err) => {
      let mockResultCode = -1; // general fetch failures (network failures, etc)
      if (err) {
        if (err.message === 'Refresh Token has expired' || err.message === 'Refresh Token has been revoked') {
          mockResultCode = -2; // expired session, requires login again
          router.push(`/${lang}${mapPath('/logout', lang)}`);
        } else if (isProduction) {
          if (err instanceof Error) captureException(err);
          else captureMessage(JSON.stringify(err));
        }
      }
      return {
        Result: mockResultCode,
        Description: t('Error_Default'),
      };
    });

    if (response.Result === 42) {
      if (call !== CARD_API.MAINTENANCE_MODE) router.push(`/${lang}${mapPath('/maintenance', lang)}`);
      logAmpEvent(events.API_MAINTENANCE_MODE, {
        Call: `API: ${call}`,
        'Result Code': response.Result,
        Description: response.Description,
        MaintenanceMode: response.MaintenanceMode,
      });
    } else if (response.Result !== 0) {
      const ampProps = {
        Call: `API: ${call}`,
        'Result Code': response.Result,
        Description: response.Description,
      };
      if (apiCallSeq) ampProps.APICALL_SEQ = apiCallSeq;
      logAmpEvent(events.API_ERROR, ampProps);
    }

    return response;
  }

  return {
    authenticate: async (Username, Password, recaptchaToken) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      const u = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({
        Username,
        Password,
        ValidationData: { recaptchaToken },
      });

      u.authenticateUser(authDetails, {
        onSuccess: (sess) => {
          cognitoLog('authenticateUser', sess);
          dispatch(setSession(sess));
          resolve(sess);
        },
        onFailure: (err) => {
          cognitoLog('authenticateUser', err, false);
          reject(err);
        },
      });
      setUser(u);
    })),
    /** @param {string} [redirectTo] path to redirect to after sign out (e.g. `/login`) */
    signOut: async (redirectTo) => (new Promise((resolve) => {
      window.Pace?.restart();
      if (!user) {
        dispatch(clearSession());
        resolve();
      } else {
        user.signOut(() => {
          dispatch(clearSession());
          setUser(null);
          if (redirectTo) router.push(`/${lang}${mapPath(redirectTo, lang)}`);
          resolve();
        });
      }
    })),

    // Signup
    /**
     * @param {string} email
     * @param {string} password
     * @param {'en' | 'fr'} language
     * @param {string} reCaptchaToken
     * @param {object} metadata key-value pairs to be passed to pre sign-up lambda as custom inputs
     */
    signUp: async (
      email,
      password,
      language,
      reCaptchaToken,
      metadata
    ) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      Pool.signUp(
        email,
        password,
        [{ Name: 'custom:language', Value: language }],
        [{ Name: 'recaptchaToken', Value: reCaptchaToken }],
        callbackWrapper('signUp', resolve, reject),
        metadata
      );
    })),

    // Confirm
    /**
     * @param {string} email email of the user confirming registration
     * @param {string} code verification code received by email
     */
    confirmRegistration: async (email, code) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: email,
        Pool,
      }).confirmRegistration(code, true, callbackWrapper('confirmRegistration', resolve, reject));
    })),
    resendConfirmationCode: async (email) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: email,
        Pool,
      }).resendConfirmationCode(callbackWrapper('resendConfirmationCode', resolve, reject));
    })),

    // forgot password flow
    resetPassword: async (email) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: email,
        Pool,
      }).forgotPassword({
        onSuccess: (data) => {
          cognitoLog('resetPassword', data);
          resolve(data);
        },
        onFailure: (err) => {
          cognitoLog('resetPassword', err, false);
          reject(err);
        },
      });
    })),
    confirmNewPassword: async (email, code, newPassword) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: email,
        Pool,
      }).confirmPassword(code, newPassword, {
        onSuccess: (data) => {
          cognitoLog('confirmNewPassword', data);
          resolve(data); // just returns 'SUCCESS'
        },
        onFailure: (err) => {
          cognitoLog('confirmNewPassword', err, false);
          reject(err);
        },
      });
    })),

    // API Calls

    // KYC API Calls
    personalInfo: async (details) => makeCall(KYC_API.PERSONAL_INFORMATION, details),
    generateReport: async () => makeCall(KYC_API.CHECK_REPORT_STATUS, { GenerateReport: true }),
    checkReportStatus: async (reportId) => makeCall(KYC_API.CHECK_REPORT_STATUS, {
      GenerateReport: false,
      CheckID: reportId,
    }),

    // User API Calls
    getUserStatus: async () => makeCall(USER_API.GET_USER_STATUS),
    setUserStatusToF: async () => makeCall(USER_API.SET_USER_STATUS),

    // Card features
    linkCard: async (info) => makeCall(CARD_API.LINK_CARD, {
      ...info,
      ProdCode: CARD_INFO.PRODCODE,
    }),
    getCardInfo: async () => makeCall(CARD_API.GET_CARD_INFO),
    transactionHistory: async (filters) => makeCall(CARD_API.TRANSACTION_HISTORY, filters),
    setCardStatus: async (Status) => makeCall(CARD_API.SET_CARD_STATUS, { Status }),
    unblockPin: async () => makeCall(CARD_API.UNBLOCK_PIN),
    checkMaintenanceMode: async () => makeCall(CARD_API.MAINTENANCE_MODE),
    activateDirectDeposit: async () => makeCall(CARD_API.ACTIVATE_DIRECT_DEPOSIT),
    updateProfile: async (info) => makeCall(CARD_API.UPDATE_PROFILE, info),
    cardReplacement: async (Reason) => makeCall(CARD_API.CARD_REPLACEMENT, { Reason }),

    // Payment
    purchaseCard: async (LoadAmount) => makeCall(PAYMENT_API.PURCHASE_CARD, { LoadAmount }),
    loadCard: async (TotalAmount) => makeCall(PAYMENT_API.LOAD_CARD, { TotalAmount }),
  };
}
