export const types = {
  SET_USER_PROXY: 'SET_PROXY',
  SET_USER_SCREENING_HAVE_CARD: 'SET_USER_SCREENING_HAVE_CARD',
  SET_USER_SCREENING_PERSONAL_ACCOUNT_USAGE: 'SET_USER_SCREENING_Q1',
  SET_USER_SCREENING_FOREIGN_TAX_RESIDENT: 'SET_USER_SCREENING_Q2',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  CLEAR_USER_DETAILS: 'CLEAR_USER_DETAILS',
  CLEAR_SESSION: 'CLEAR_SESSION',
};

export const setUserHaveCard = (answer) => ({
  type: types.SET_USER_SCREENING_HAVE_CARD,
  payload: answer,
});

export const setUserPersonalAccountUsage = (answer) => ({
  type: types.SET_USER_SCREENING_PERSONAL_ACCOUNT_USAGE,
  payload: answer,
});

export const setUserForeignTaxResident = (answer) => ({
  type: types.SET_USER_SCREENING_FOREIGN_TAX_RESIDENT,
  payload: answer,
});

export const setUserDetails = (details) => ({
  type: types.SET_USER_DETAILS,
  payload: details,
});

export const clearUserDetails = () => ({
  type: types.CLEAR_USER_DETAILS,
});
