import { t, translate, setLanguage, getLanguage } from 'react-switch-lang';
import Image from 'next/legacy/image';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';

import Link, { ExternalLink } from './Link';
import { useRouter } from './Router';
import { events, logAmpEvent } from '../utils/Amplitude';
import cardImage from '../public/assets/images/card/CardFront.png';
import googleApp from '../public/assets/images/logos/Google.png';
import googleAppFr from '../public/assets/images/logos/GoogleFr.png';
import appleApp from '../public/assets/images/logos/Apple.png';
import appleAppFr from '../public/assets/images/logos/AppleFr.png';
import { getSessionAndAttr } from '../utils/Cognito';
import { setSession } from '../redux/actions/AuthActions';
import { mapHash, mapPath } from '../utils/FrenchUrlMapping';

function DownloadAppLinks({ store, title, image, alt, link }) {
  const router = useRouter();

  return (
    <ExternalLink
      href={link}
      title={t(title)}
      className="download-app"
      ampEvent={events.USER_CLICKED_DOWNLOAD_APP}
      ampEventProps={{
        Page: router.pathname.replace('[lang]', '').replace('//', '/'),
        Section: 'Footer',
        Store: store,
      }}
    >
      <div className="footer-store-buttons">
        <Image src={image} alt={t(alt)} unoptimized />
      </div>
    </ExternalLink>
  );
}

function Column({ header, children }) {
  return (
    <div className="col-md-2">
      <h3 className="footer-title">{t(header)}</h3>
      {children}
    </div>
  );
}

function languageToFull() {
  return getLanguage() === 'fr' ? 'Français' : 'English';
}

function widgetLanguage(lang) {
  const iframe = document.getElementById('iframe-widget');
  if (!iframe?.src) return;
  iframe.src = iframe.src.slice(0, -2) + lang;

  const globalVarFromHelpCentreScript = WidgetText; /* eslint-disable-line no-undef */
  if (!globalVarFromHelpCentreScript) return;
  document.getElementById('widget-button')?.setAttribute('aria-label', globalVarFromHelpCentreScript[lang]);
  document.getElementById('widget-button-tooltip')?.replaceChildren(globalVarFromHelpCentreScript[lang]);
}

function LanguageDropdown() {
  const router = useRouter();
  const ar = router.pathname.split('[lang]');
  const curPath = ar[ar.length - 1];

  const dispatch = useDispatch();

  const sessionExists = useSelector((state) => !!state.auth.session);

  function LangBtn({ lang, children, ...props }) {
    return (
      <Dropdown.Item
        {...props}
        onClick={async () => {
          if (getLanguage() === lang) return;
          logAmpEvent(events.USER_CHANGED_LANGUAGE, { Language: lang === 'fr' ? 'French' : 'English' });
          document.documentElement.lang = lang;
          if (sessionExists) {
            const [sess] = await getSessionAndAttr(lang).catch(() => [null]);
            if (sess) {
              dispatch(setSession(sess));
            }
          }
          widgetLanguage(lang);
          if (router.pathname === '/404') {
            setLanguage(lang);
            router.pushStatic('/404', router.asPath);
          } else {
            router.pushStatic(`/${lang}${mapPath(curPath, lang)}`);
          }
        }}
        aria-label={t('Language_Switch_To', null, lang)}
        lang={lang}
      >
        {children}
      </Dropdown.Item>
    );
  }

  return (
    <Dropdown className="dropdown-language">
      <Dropdown.Toggle id="dropbtn">
        <div className="dropdown-display">
          <FontAwesomeIcon icon={['fal', 'globe-americas']} />
          <span className="curLang">{languageToFull()}</span>
          <FontAwesomeIcon icon={['fas', 'chevron-down']} />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <LangBtn lang="en">English</LangBtn>
        <LangBtn lang="fr" style={{ marginTop: '5px' }}>
          Français
        </LangBtn>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Footer() {
  const lang = getLanguage();
  const googleImg = lang === 'fr' ? googleAppFr : googleApp;
  const appleImg = lang === 'fr' ? appleAppFr : appleApp;

  return (
    <div id="footer" className="footer-top container-fluid">
      <div className="row justify-content-center cont mx-auto px-0">
        <div className="col-md-4">
          <h3 className="footer-title footer-card-label">{t('OfficialCardName')}</h3>
          <div style={{ width: '10em' }}>
            <Image
              id="mag-card-img"
              src={cardImage}
              alt=""
              aria-hidden="true"
              unoptimized
            />
            <LanguageDropdown />
          </div>
        </div>
        <Column header="Settings_Card_Heading">
          <Link
            href="/purchase"
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('Footer_Lbl_Purchase', null, 'en') }}
            text={t('Footer_Lbl_Purchase')}
          />
          <Link
            href={{ pathname: '/', hash: mapHash('CardBenefits', lang) }}
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('Landing_Title_Benefits_Header', null, 'en') }}
            text={t('Landing_Title_Benefits_Header')}
          />
          <Link
            href="/cardholder-agreement"
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('Footer_Lbl_Fee_Limits', null, 'en') }}
            text={t('Footer_Lbl_Fee_Limits')}
          />
        </Column>
        <Column header="Footer_Title_Support">
          <ExternalLink
            href={t('Link_Maple_Help')}
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('Hamburger_HelpCentre', null, 'en') }}
            text={t('Hamburger_HelpCentre')}
          />
          <ExternalLink
            href={t('Link_Maple_ContactUsPage')}
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('Footer_Lbl_Contact_Us', null, 'en') }}
            text={t('Footer_Lbl_Contact_Us')}
          />
        </Column>
        <Column header="MyCard_Home_Legal_Title">
          <Link
            href="/cardholder-agreement"
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('MyCard_Home_Cardholder_Agreement', null, 'en') }}
            text={t('MyCard_Home_Cardholder_Agreement')}
          />
          <Link
            href="/terms-of-service"
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('General_Text_Lbl_Terms_Service', null, 'en') }}
            text={t('General_Text_Lbl_Terms_Service')}
          />
          <ExternalLink
            href={t('Link_Peoples_Privacy')}
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('MyCard_Home_Peoples_Trust_Privacy_Policy', null, 'en') }}
            text={t('MyCard_Home_Peoples_Trust_Privacy_Policy')}
          />
          <ExternalLink
            href={t('Link_PSI_Privacy')}
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('Footer_Lbl_PSI_Privacy_Policy', null, 'en') }}
            text={t('Footer_Lbl_PSI_Privacy_Policy')}
          />
          <ExternalLink
            href={t('Link_AccessibilityStatement')}
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('Footer_Lbl_Accessibility', null, 'en') }}
            title={t('Footer_Link_Title_Accessibility')}
            text={t('Footer_Lbl_Accessibility')}
          />
        </Column>
        <Column header="MyCard_Home_MobileApp_Title">
          <DownloadAppLinks
            store="Apple Store"
            title="LinkDescription_Maple_AppleAppStore"
            image={appleImg}
            alt="ImageDescription_Maple_AppleAppStore"
            link={t('Link_MapleiOSApp')}
          />
          <DownloadAppLinks
            store="Google Play"
            title="LinkDescription_Maple_GooglePlayStore"
            image={googleImg}
            alt="ImageDescription_Maple_GooglePlayStore"
            link={t('Link_MapleAndroidApp')}
          />
        </Column>
      </div>
    </div>
  );
}

export default translate(Footer);
