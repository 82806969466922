export const types = {
  SET_CARD_INFO: 'SET_CARD_INFO',
  NO_CARD_LINKED: 'NO_CARD_LINKED',
  SET_CARD_STATUS: 'SET_CARD_STATUS',
  SET_DIRECT_DEPOSIT_INFO: 'SET_DIRECT_DEPOSIT_INFO',
  SET_DIRECT_DEPOSIT_ACTIVE: 'SET_DIRECT_DEPOSIT_ACTIVE',
  SET_ACCOUNT_INFO: 'SET_ACCOUNT_INFO',
  CLEAR_CARD_INFO: 'CLEAR_CARD_INFO',
  CLEAR_SESSION: 'CLEAR_SESSION',
  UPDATE_PROXY: 'UPDATE_PROXY',
};

export const setCardStatus = (status) => ({
  type: types.SET_CARD_STATUS,
  payload: status,
});

/**
 * @param {object} info response from GetCardInfo call;
 * please refer to API docs for format
 */
export const setCardInfo = (info) => ({
  type: types.SET_CARD_INFO,
  payload: {
    proxy: info.CardProxy,
    status: info.Load.CardStatus,
    balance: info.Balance,
    load: {
      max: info.Load.Max,
      min: info.Load.Min,
      fee: info.Load.Fee,
      status: info.Load.VettedStatus,
    },
    alerts: info.CardholderAlerts,
    addressUpdateRequired: info.AddressUpdateRequired,
  },
});
export const noCardLinked = () => ({
  type: types.NO_CARD_LINKED,
});

export const setDirectDepositInfo = (directDeposit) => ({
  type: types.SET_DIRECT_DEPOSIT_INFO,
  payload: directDeposit,
});

export const setDirectDepositActive = (active) => ({
  type: types.SET_DIRECT_DEPOSIT_ACTIVE,
  payload: active,
});

export const setAccountInfo = (account) => ({
  type: types.SET_ACCOUNT_INFO,
  payload: account,
});

export const clearCardInfo = () => ({ type: types.CLEAR_CARD_INFO });

export const updateProxy = (proxy) => ({
  type: types.UPDATE_PROXY,
  payload: proxy,
});
