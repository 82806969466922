import NextLink from 'next/link';
import { getLanguage, t } from 'react-switch-lang';
import { events, logAmpEvent } from '../utils/Amplitude';
import { mapPath } from '../utils/FrenchUrlMapping';

function appendSuffixConditionally(text, suffix) {
  if (!text) return text;
  if (text.toLowerCase().includes(suffix.toLowerCase())) return text;
  return text + suffix;
}

function AddTargetBlankLinkDescription(props, text, children) {
  const suffix = ` - ${t('LinkDescription_TargetBlank')}`;

  // append to existing link descriptions

  // eslint-disable-next-line no-param-reassign
  props.title = appendSuffixConditionally(props.title, suffix);
  // eslint-disable-next-line no-param-reassign
  props['aria-label'] = appendSuffixConditionally(props['aria-label'], suffix);

  // add default link description if there aren't any
  if (!props.title && !props['aria-label'] && (text || typeof children === 'string')) {
    props['aria-label'] = `${(text || children)}${suffix}`; // eslint-disable-line no-param-reassign
  }
}

export default function Link({
  href, children, text = '', ampEvent, ampEventProps, className = '', ...props
}) {
  let path;
  const lang = getLanguage();
  if (typeof href === 'string') {
    path = `/${lang}${mapPath(href, lang)}`;
  } else {
    path = { ...href, pathname: `/${lang}${mapPath(href.pathname, lang)}` };
  }
  if (props.target === '_blank') AddTargetBlankLinkDescription(props, text, children);

  return (
    <NextLink
      href={path}
      passHref
      className={`mapleLink ${className}`}
      {...props}
      onClick={
        (props.onClick || ampEvent) &&
        ((e) => {
          if (ampEvent && events[ampEvent]) logAmpEvent(ampEvent, ampEventProps);
          props.onClick?.(e);
        })
      }
    >
      {text || children}
    </NextLink>
  );
}

export function ExternalLink({
  href, children, text = '', ampEvent, ampEventProps, className = '', ...props
}) {
  if (!props.target || props.target === '_blank') AddTargetBlankLinkDescription(props, text, children);

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={`mapleLink ${className}`}
      {...props}
      onClick={
        (props.onClick || ampEvent) &&
        ((e) => {
          if (ampEvent && events[ampEvent]) logAmpEvent(ampEvent, ampEventProps);
          props.onClick?.(e);
        })
      }
    >
      {text || children}
    </a>
  );
}
