import { types } from '../actions/Actions';

const initialState = {
  title: undefined,
  description: {
    en: undefined,
    fr: undefined,
  },
};

function AnnouncementReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ANNOUNCEMENT:
      return {
        title: action.payload?.Title,
        description: {
          en: action.payload?.Description,
          fr: action.payload?.DescriptionFrench,
        },
      };
    default:
      return state;
  }
}

export default AnnouncementReducer;
