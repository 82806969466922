import { types } from '../actions/AuthActions';

const initialState = {
  session: null,
  email: null,
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_EMAIL:
      return { ...state, email: action.payload };
    case types.SET_SESSION:
      return { ...state, session: action.payload };
    case types.CLEAR_SESSION:
      return initialState;
    default:
      return state;
  }
}

export default AuthReducer;
