import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getLanguage } from 'react-switch-lang';
import { mapPath } from '../utils/FrenchUrlMapping';

export default function Redirect({ path, asIs }) {
  const router = useRouter();
  useEffect(() => {
    const lang = getLanguage();
    router.replace(asIs ? path : `/${lang}${mapPath(path, lang)}`);
  }, [path, router, asIs]);
  return <></>;
}
