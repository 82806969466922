import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/legacy/image';
import { t, translate, getLanguage } from 'react-switch-lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link, { ExternalLink } from './Link';
import Announcement from './Announcement';
import { useRouter } from './Router';
import { events, logAmpEvent } from '../utils/Amplitude';
import { CARD_FEATURES } from '../utils/Constants';
import logo from '../public/assets/images/CanadaPostLogo.png';
import logoFr from '../public/assets/images/CanadaPostLogoFr.png';
import style from '../styles/NavBar.module.scss';
import ReplaceTextWithElement from './ReplaceTextWithElement';
import { isDevelopment } from '../utils/HostingEnv';
import { mapHash } from '../utils/FrenchUrlMapping';

function Hide({ hide, children }) {
  return hide ? <></> : children;
}

function NavBar({ isLandingPage, isMyCardPage, isLinkCardPage, hideOnDesktop }) {
  const [showAboutDropdown, toggleAboutDropdown] = useState(false);
  const [showHamburgerDropdown, toggleHamburgerDropdown] = useState(false);
  const [showText, setShowText] = useState(true);
  const [showTextTimeout, setShowTextTimeout] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const session = useSelector((state) => state.auth.session);
  const router = useRouter();
  const lang = getLanguage();

  useEffect(() => {
    if (!isLandingPage) return undefined;
    function onScroll() {
      if (window.pageYOffset > 0) {
        document.querySelector(`#${style.nav}`).classList.remove(style.atTop);
      } else {
        document.querySelector(`#${style.nav}`).classList.add(style.atTop);
      }
    }
    document.addEventListener('scroll', onScroll);
    onScroll();

    return () => document.removeEventListener('scroll', onScroll);
  }, [isLandingPage, session]);

  useEffect(() => {
    if (!showHamburgerDropdown) {
      if (showTextTimeout) return;
      setShowTextTimeout(
        setTimeout(() => {
          setShowText(true);
          setShowTextTimeout(null);
        }, 250)
      );
    } else {
      if (showTextTimeout) {
        clearTimeout(showTextTimeout);
        setShowTextTimeout(null);
      }
      setShowText(false);
    }
  }, [showHamburgerDropdown]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => (window.innerWidth < 768 ? setIsMobileView(true) : undefined), []);
  useEffect(() => {
    let timeout;
    function debouncedResizeCallback() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (!isMobileView && window.innerWidth < 768) setIsMobileView(true);
        if (isMobileView && window.innerWidth >= 768) setIsMobileView(false);
      }, 100);
    }

    window.addEventListener('resize', debouncedResizeCallback);

    return () => window.removeEventListener('resize', debouncedResizeCallback);
  }, [isMobileView, isMyCardPage, showHamburgerDropdown]);

  return (
    <nav id={style.nav} className={hideOnDesktop ? style.hideOnDesktop : ''} style={(isMyCardPage || isLinkCardPage) ? { '--nav-bar-bg-color': '#f8f8f8' } : undefined}>
      <div className={`${style.navContent} cont ${showHamburgerDropdown ? style.expanded : ''}`}>
        <div className={style.row}>
          <button
            id={style.Hamburger}
            className={style.corners}
            type="button"
            onClick={() => toggleHamburgerDropdown(!showHamburgerDropdown)}
          >
            <FontAwesomeIcon icon={['fas', 'bars']} />
          </button>
          <Link
            href="/"
            className={style.logo}
            ampEvent={events.USER_CLICKED_NAVBAR_LINK}
            ampEventProps={{ Link: 'Logo' }}
            onFocus={() => toggleAboutDropdown(false)}
            onClick={() => toggleHamburgerDropdown(false)}
          >
            <Image
              src={lang === 'fr' ? logoFr : logo}
              className="canada-post-logo"
              alt={t('ImageDescription_CanadaPostLogo')}
              layout="fill"
              objectFit="contain"
              unoptimized
            />
          </Link>

          <div className={style.corners} />
          <div id={style.Links}>
            {/* the extra div here is necessary for collapsible css to work properly */}
            <div>
              {isDevelopment && (
                <Link
                  id={style.siteMapButton}
                  href="/site-map"
                  text="Testserver"
                  onClick={() => toggleHamburgerDropdown(false)}
                />
              )}
              <Hide hide={isMyCardPage || isLinkCardPage}>
                <div className={style.dropdown} onMouseLeave={() => toggleAboutDropdown(false)}>
                  <button
                    type="button"
                    id={style.DropBtn}
                    aria-expanded={showAboutDropdown}
                    // if clicked with a mouse, don't focus element by preventing default
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      // if element is focused, button was clicked by pressing the enter key
                      if (e.target === document.activeElement) {
                        toggleAboutDropdown(!showAboutDropdown);
                        logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, { Link: t('Footer_Lbl_About', null, 'en') });
                      }
                    }}
                    onMouseEnter={() => toggleAboutDropdown(true)}
                  >
                    {`${t('Footer_Lbl_About')} `}
                    <FontAwesomeIcon icon={['fal', 'chevron-down']} size="xs" />
                  </button>
                  <div
                    className={`${style.dropContent} ${showAboutDropdown ? style.show : ''}`}
                    onMouseLeave={() => toggleAboutDropdown(false)}
                  >
                    <Link
                      href={{ pathname: '/', hash: mapHash('CardBenefits', lang) }}
                      ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                      ampEventProps={{ Link: t('Landing_Title_Benefits_Header', null, 'en') }}
                      text={t('Landing_Title_Benefits_Header')}
                      onClick={() => toggleHamburgerDropdown(false)}
                    />
                    <Link
                      href={{ pathname: '/', hash: mapHash('CardholderApps', lang) }}
                      ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                      ampEventProps={{ Link: t('Navbar_Lbl_Cardholder_Apps', null, 'en') }}
                      text={t('Navbar_Lbl_Cardholder_Apps')}
                      onClick={() => toggleHamburgerDropdown(false)}
                    />
                    <Link
                      href="/cardholder-agreement"
                      ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                      ampEventProps={{ Link: t('Footer_Lbl_Fee_Limits', null, 'en') }}
                      text={t('Footer_Lbl_Fee_Limits')}
                      onClick={() => toggleHamburgerDropdown(false)}
                    />
                  </div>
                </div>
              </Hide>
              <ExternalLink
                href={t('Link_Maple_Help')}
                ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                ampEventProps={{ Link: t('HelpCentreTxt', null, 'en') }}
                text={t('HelpCentreTxt')}
              />
              <Hide hide={!isMyCardPage}>
                <Link
                  href="/cardholder-agreement"
                  ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                  ampEventProps={{ Link: t('MyCard_Home_Cardholder_Agreement', null, 'en') }}
                  text={t('MyCard_Home_Cardholder_Agreement')}
                  target="_blank"
                  onClick={() => toggleHamburgerDropdown(false)}
                />
                <Link
                  href="/electronic-consent-agreement"
                  ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                  ampEventProps={{ Link: t('MyCard_Home_Legal_Electronic_Consent', null, 'en') }}
                  text={t('MyCard_Home_Legal_Electronic_Consent')}
                  target="_blank"
                  onClick={() => toggleHamburgerDropdown(false)}
                />
                <ExternalLink
                  href={t('Link_Peoples_Privacy')}
                  ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                  ampEventProps={{ Link: t('MyCard_Home_Peoples_Trust_Privacy_Policy', null, 'en') }}
                  text={t('MyCard_Home_Peoples_Trust_Privacy_Policy')}
                  onClick={() => toggleHamburgerDropdown(false)}
                />
                <button
                  type="button"
                  onClick={() => {
                    toggleHamburgerDropdown(false);
                    logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, { Link: t('MyCard_Home_Settings_Title', null, 'en') });
                    if (typeof isMyCardPage === 'function') isMyCardPage(CARD_FEATURES.SETTINGS);
                  }}
                >
                  {t('MyCard_Home_Settings_Title')}
                </button>
              </Hide>
              {session ? (
                <>
                  <Hide hide={isMyCardPage || isLinkCardPage}>
                    <Link
                      href="/home"
                      ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                      ampEventProps={{ Link: t('Navbar_Lbl_My_Card', null, 'en') }}
                      text={t('Navbar_Lbl_My_Card')}
                      onFocus={() => toggleAboutDropdown(false)}
                      onClick={() => toggleHamburgerDropdown(false)}
                    />
                  </Hide>
                  <button
                    id={style.LogoutBtn}
                    type="button"
                    onClick={() => {
                      toggleHamburgerDropdown(false);
                      logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, { Link: t('Hamburger_Logout', null, 'en') });
                      router.push('/logout');
                    }}
                  >
                    {t('Hamburger_Logout')}
                  </button>
                </>
              ) : (
                <>
                  <Link
                    href="/register"
                    ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                    ampEventProps={{ Link: t('Create_Account_Btn', null, 'en') }}
                    onFocus={() => toggleAboutDropdown(false)}
                    text={t('Create_Account_Btn')}
                    onClick={() => toggleHamburgerDropdown(false)}
                  />
                  <Link
                    href="/login"
                    ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                    ampEventProps={{ Link: t('Login_Title', null, 'en') }}
                    text={t('Login_Title')}
                    onClick={() => toggleHamburgerDropdown(false)}
                  />
                  <Link
                    id={style.PurchaseBtn}
                    href="/purchase"
                    ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                    ampEventProps={{ Link: t('Navbar_Lbl_Purchase_Card', null, 'en') }}
                    text={t('Navbar_Lbl_Purchase_Card')}
                    onClick={() => toggleHamburgerDropdown(false)}
                  />
                </>
              )}
              <Hide hide={!isMyCardPage}>
                <div className="footer-bottom">
                  <div className="max-container-center">
                    <p className="footerSubHeader">
                      {t('Footer_Sub_Header')}
                    </p>
                    <p>
                      <ReplaceTextWithElement
                        text={t('Footer_Sub_Text')}
                        replaceWith={
                          <i>Interac</i>
                        }
                        replaceKey="Interac"
                        exceptions={[' Corp.']}
                      />
                    </p>
                  </div>
                </div>
              </Hide>
            </div>
          </div>
        </div>
        <Hide hide={isMyCardPage || isLinkCardPage}><p className={`${style.text} ${showText ? style.show : ''}`}>{t('OfficialCardName')}</p></Hide>
      </div>
      <Announcement />
    </nav>
  );
}

export default translate(NavBar);
