import { t } from 'react-switch-lang';
import Head from 'next/head';
import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import SkipToNav from './SkipToNav';
import ReplaceTextWithElement from './ReplaceTextWithElement';
import { setPageTitle } from '../utils/Accessibility';

export default function MainLayout({
  title,
  description,
  isLandingPage,
  isMyCardPage,
  isLinkCardPage,
  loadAddressCompleteStylesheet,
  showNavBar = true,
  showFooter = true,
  hideNavOnDesktop,
  children,
  className = '',
}) {
  setPageTitle(title, isLandingPage);

  return (
    <>
      <Head>
        <title>{isLandingPage ? title : `${title} | ${t('OfficialCardName')}`}</title>
        <meta name="description" content={description} />
        {loadAddressCompleteStylesheet && (
          <link async rel="stylesheet" href="https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key=BE31-JF91-NX89-YZ44" />
        )}
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div id="main-cont">
        <SkipToNav />
        {showNavBar && (
          <NavBar
            isLandingPage={isLandingPage}
            isMyCardPage={isMyCardPage}
            isLinkCardPage={isLinkCardPage}
            hideOnDesktop={hideNavOnDesktop}
          />
        )}
        <div id="main" className={`content ${className}`}>{children}</div>
        <footer className={`w-100${isMyCardPage ? ' myCard' : ''}`}>
          {showFooter && <Footer />}
          <div id="footer" className="footer-bottom">
            <div className="max-container-center">
              <p className="footerSubHeader">
                {t('Footer_Sub_Header')}
              </p>
              <p className="p-3">
                <ReplaceTextWithElement
                  text={t('Footer_Sub_Text')}
                  replaceWith={
                    <i>Interac</i>
                  }
                  replaceKey="Interac"
                  exceptions={[' Corp.']}
                />
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
