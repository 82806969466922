import { combineReducers } from 'redux';

import AuthReducer from './AuthReducer';
import PurchaseReducer from './PurchaseReducer';
import CardReducer from './CardReducer';
import AnnouncementReducer from './AnnouncementReducer';

export default combineReducers({
  auth: AuthReducer,
  purchase: PurchaseReducer,
  card: CardReducer,
  announcement: AnnouncementReducer,
});
