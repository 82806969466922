import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { getLanguage, t } from 'react-switch-lang';
import ReactMarkdown from 'react-markdown';

import { localStorageGet, localStorageSet } from '../utils/Helpers';

const MS_IN_DAY = 86400000; // milliseconds in a day
const STORAGE_KEY = 'customer_announcements'; // localStorage key for customer announcements

function isDismissedWithin30Days(title) {
  const dismissedTime = JSON.parse(localStorageGet(STORAGE_KEY))?.[title];
  return dismissedTime && (Date.now() - dismissedTime) / MS_IN_DAY < 30;
}

export default function Announcement({ id }) {
  const [isClosed, setIsClosed] = useState(false);
  const {
    title,
    description: { [getLanguage()]: msg },
  } = useSelector((state) => state.announcement);

  const shouldDisplayBanner = useMemo(() => (
    title && msg && !isClosed && !isDismissedWithin30Days(title)
  ), [title, msg, isClosed]);

  if (!shouldDisplayBanner) return <></>;

  return (
    <div id={id} className="banner-cont">
      <ReactMarkdown
        components={{
          strong: 'b',
          p: ({ node, ...props }) => <React.Fragment {...props} />,
          a: ({ node, children, ...props }) => <a {...props} target="_blank">{children}</a>,
        }}
      >
        {msg}
      </ReactMarkdown>
      <button
        aria-label={t('ButtonDescription_DismissAnnouncement')}
        title={t('ButtonDescription_DismissAnnouncement')}
        className="dismissBtn"
        type="button"
        onClick={() => {
          let dismissedMsgs = JSON.parse(localStorageGet(STORAGE_KEY));

          if (!dismissedMsgs) {
            dismissedMsgs = { [title]: Date.now() };
          } else {
            // delete msgs which have been dismissed more than 30 days ago
            dismissedMsgs = Object.entries(dismissedMsgs).reduce((acc, [key, value]) => {
              if ((Date.now() - value) / MS_IN_DAY > 30) return acc;

              acc[key] = value;
              return acc;
            }, {});

            dismissedMsgs[title] = Date.now(); // add current msg to dismissed msg list
          }

          localStorageSet(STORAGE_KEY, JSON.stringify(dismissedMsgs));
          setIsClosed(true);
        }}
      >
        <FontAwesomeIcon icon={['fas', 'times']} />
      </button>
    </div>
  );
}
