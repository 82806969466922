const pathMap = [
  ['/cardholder-agreement', '/convention-de-titulaire-de-carte'],
  ['/electronic-consent-agreement', '/accord-de-consentement-electronique'],
  ['/error', '/erreur'],
  ['/forgot-password', '/reinitialiser-le-mot-de-passe'],
  ['/home', '/accueil'],
  ['/link-card', '/lier-une-carte'],
  ['/login', '/connexion'],
  ['/logout', '/deconnexion'],
  ['/maintenance', '/entretien'],
  ['/my-card', '/ma-carte'],
  ['/purchase', '/achat'],
  ['/register', '/inscription'],
  ['/slp-cardholder-agreement', '/convention-de-titulaire-de-carte-slp'],
  ['/terms-of-service-mobile', '/conditions-de-utilisation-mobile'],
  ['/terms-of-service', '/conditions-de-utilisation'],
].reduce((acc, cur) => {
  const map = {
    en: cur[0],
    fr: cur[1],
  };
  acc[cur[0]] = map;
  acc[cur[1]] = map;
  return acc;
}, {});
const hashMap = Object.freeze({
  CardBenefits: {
    en: 'CardBenefits',
    fr: 'avantages-de-la-carte',
  },
  CardholderApps: {
    en: 'CardholderApps',
    fr: 'applications-du-titulaire-de-carte',
  },
});

export function mapPath(path, lang) {
  if (!pathMap[path]?.[lang]) return path;
  return pathMap[path][lang];
}

export function mapPathArray(pathArray) {
  const newArray = [];
  pathArray.forEach((path) => {
    if (!pathMap[path]) {
      newArray.push(path);
    } else {
      newArray.push(pathMap[path].en);
      newArray.push(pathMap[path].fr);
    }
  });
  return newArray;
}

export function mapHash(hash, lang) {
  if (!hashMap[hash]?.[lang]) return hash;
  return hashMap[hash][lang];
}
