export const CARD_INFO = {
  PRODCODE: 'CPVRP1',
  CARD_PURCHASE_PRICE: 10, // Payment Engine and Maple API both have this hardcoded too
  CARD_PURCHASE_MAX: 500,
  CARD_PURCHASE_MIN: 25,
};

export const AUTH_STEP = {
  LOGIN: 'Login',
  REGISTER: 'Register',
  CONFIRM_EMAIL: 'Confirm Email',
  FORGOT_PASSWORD: 'Forgot Password',
};

export const AUTH_API = {
  LOGIN: 'Authenticate',
  REGISTER: 'SignUp',
  CONFIRM_EMAIL: 'ConfirmSignUp',
  RESEND_CONFIRM_EMAIL: 'ResendConfirmationCode',
  FORGOT_PASSWORD: 'ForgotPassword',
  CONFIRM_FORGOT_PASSWORD: 'ConfirmForgotPassword',
};

export const PURCHASE_STEP = {
  UNAUTHENTICATED: 'Unauthenticated',
  BEGIN: 'Begin',
  SCREENING: 'Screening',
  DETAILS: 'Details',
  REVIEW: 'Review',
  IDENTIFICATION: 'Identification',
  PAYMENT: 'Payment',
};

export const E_TRANSFER_PAYMENT_STEP = {
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  ENTER_AMOUNT: 'ENTER_AMOUNT',
  E_TRANSFER_CODES: 'E_TRANSFER_CODES',
};

export const KYC_API = {
  PERSONAL_INFORMATION: 'KycReg',
  CHECK_REPORT_STATUS: 'KycCheckReport',
};

export const USER_API = {
  GET_USER_STATUS: 'GetUserStatus',
  SET_USER_STATUS: 'SetUserStatusF',
};

export const CARD_API = {
  LINK_CARD: 'LinkCard',
  GET_CARD_INFO: 'GetCardInfo',
  TRANSACTION_HISTORY: 'TransHistory',
  SET_CARD_STATUS: 'SetCardStatus',
  UNBLOCK_PIN: 'PinUnblock',
  MAINTENANCE_MODE: 'MaintenanceMode',
  ACTIVATE_DIRECT_DEPOSIT: 'ActivateDirectDeposit',
  UPDATE_PROFILE: 'UpdateProfile',
  CARD_REPLACEMENT: 'CardReplacement',
};

export const PAYMENT_API = {
  PURCHASE_CARD: 'PurchaseCard',
  LOAD_CARD: 'LoadCard',
};

export const SCREENING_STEP = {
  HAVE_CARD_PROMPT: 'Have Card - Prompt',
  HAVE_CARD_FAILED: 'Have Card - Failed',
  ACCOUNT_USAGE_PROMPT: 'Account Usage - Prompt',
  ACCOUNT_USAGE_FAILED: 'Account Usage - Failed',
  FOREIGN_TAX_RESIDENT_PROMPT: 'Foreign Tax Resident - Prompt',
  FOREIGN_TAX_RESIDENT_FAILED: 'Foreign Tax Resident - Failed',
};

export const CARD_FEATURES = {
  HOME: 'Home',
  TRANSACTIONS: 'Transactions',
  LOAD: 'Reload',
  MANAGE_CARD: 'Manage Card',
  SETTINGS: 'Settings',
  DIRECT_DEPOSIT: 'Direct Deposit',
  PERSONAL_INFORMATION: 'Personal Information',
};

export const LEGAL_DOCUMENTS = {
  CARDHOLDER_AGREEMENT: 'Cardholder Agreement',
  TERMS_OF_SERVICE: 'Terms of Service',
  PSI_PRIVACY_POLICY: 'Payment Source Privacy Policy',
  ACCESSIBILITY: 'Accessibility',
  ELECTRONIC_CONSENT_AGREEMENT: 'Electronic Consent Agreement',
  PEOPLES_PRIVACY_POLICY: 'Peoples Trust Privacy Policy',
  ONFIDO_TERMS_OF_USE: 'Onfido Terms Of Use',
  CANADA_POST_PRIVACY_POLICY: 'Canada Post Privacy Policy',
};

export const LEGAL_DOCUMENTS_LINKS = {
  CARDHOLDER_AGREEMENT: {
    en: {
      PUBLISHEDID: '2PACX-1vRS6dhfZVaE7k7ut1AXPI5Bq1TN7__kmLCl5JVkLnqqqzJe9lngb6fXj4St_mHydIaTUUqatV0cxDfq',
      PRINTID: '1pMnYXa_P6kfdH5W3h_zrOh4y_tV9CnKZug0ts0Gz7Lk',
    },
    fr: {
      PUBLISHEDID: '2PACX-1vTQ0QgVwT-9VHXwZTdw80yGr9dOPgIMHNgB7-GQIW4VdZtCvNYQ7u8OzhTW6MlHqEORoFdTckVOu4cq',
      PRINTID: '1TXYHeA1Tn_2pWiaOVfsrNG346fnyDvmYaRQp8k77Seo',
    },
  },
  WEB_TERMS_OF_SERVICE: {
    en: {
      PUBLISHEDID: '2PACX-1vTSf3YT2CjqUEbRG4ZeOvskmJ3Uh7w4I8LfVelcXQQqZjowzrsWNTstiHgplD6_RMCv9AtA1pexdxCz',
      PRINTID: '1wsOzfbXZyoZDqOfWamM2XlkYkLmcZIS4G1nsxps_CGI',
    },
    fr: {
      PUBLISHEDID: '2PACX-1vRnNliBGfDNueKgmCvOp0Y29igLmsXKGPONlCDSeB1JTqnA5yiVq6I9vME7da6SExVS1Eg-Iy26g6t0',
      PRINTID: '1KP9nSf9nwOuSfQqrqDctjvHwu33m6S4nMnC6Vi21AXw',
    },
  },
  MOBILE_TERMS_OF_SERVICE: {
    en: {
      PUBLISHEDID: '2PACX-1vQDdgqB4X6AMFnufm6ibf7Eu6etH5waVyi9DWzCP6FetizdJg1-zX_FHKxhzYY7rwkr6Z9PrLDUzCNz',
      PRINTID: '1PHqUdYEPs8IRKgJBRzrHyjsXAdYqIm8cIhfBa3B3gdc',
    },
    fr: {
      PUBLISHEDID: '2PACX-1vRDiu57ZwmSNu7O5jw28fvK-Jp-TJDPDBg6bz0TqUPIGgi44fCbIynpvmZhZhS7kBZOMToGtiAygJQh',
      PRINTID: '1KsjOz3hz1EyIApe7_uWDjwTGZTdjooEIlrykMRBOlMc',
    },
  },
  ELECTRONIC_CONSENT_AGREEMENT: {
    en: {
      PUBLISHEDID: '2PACX-1vTb0RqSmtUHM3-OtAAAquSCVw9IJOkPoYrE0KsnsZI_UuqpV9mEsb3kFieAxTX-PmM9noUSUHatQooZ',
      PRINTID: '1s2BNOOR3gWDLcRXrHXbLZJ_ZL_sjGp2AGdyhJ3bjE6w',
    },
    fr: {
      PUBLISHEDID: '2PACX-1vRQ7ItRqfN7i7wLPBT-S4EP6WD6O_zlA6CygeQhlJyWiFVCd_tBhK4efXJlgI6iS3pCdJLCCr_mfIDx',
      PRINTID: '1AJUc4idMcqJyTLcXHqNggGfGPbiM8ntvX0ZFu1fLCa0',
    },
  },
  SLP_CARDHOLDER_AGREEMENT: {
    en: {
      PUBLISHEDID: '2PACX-1vTb6FGGs4z___ySK0pDsnrmhGDe-DlECYllLf825OqgRHkhMbjua6w0c_dnlgJbUiTcic7fawxHTe_-',
      PRINTID: '1u5HLpbe6uoKna6DrfTK_29RS1lj9EKcNvPovBQFYeXI',
    },
    fr: {
      PUBLISHEDID: '2PACX-1vQ6xOtfw-EQXXmHwoy4WYnwbxU7VYSmJ00DR79ux4q-m5kOXvr-9YqPOaKKt32wG9uGvqXP2BoPuGQg',
      PRINTID: '1e2MH6f6tfHfjUq8qn6mPkLnSDbDtU2B42QUE4L10Msk',
    },
  },
};

// Don't change these constant!!
export const CARD_MANAGEMENT_ACTION = {
  OVERVIEW: 'Overview',
  SET_CARD_STATUS: 'Set Card Status',
  UNBLOCK_PIN: 'Unblock Pin',
  SUSPEND_CARD: 'Suspend',
  UNSUSPEND_CARD: 'Unsuspend',
  CARD_REPLACEMENT_REASON: 'Card Replacement',
  CARD_REPLACEMENT_TERMS: 'CARD_REPLACEMENT_TERMS',
  CARD_REPLACEMENT_CONFIRM: 'CARD_REPLACEMENT_CONFIRM',
  CARD_REPLACEMENT_SUCCESS: 'CARD_REPLACEMENT_SUCCESS',
  CARD_REPLACEMENT_INSUFFICIENT_FUNDS_ERROR: 'CARD_REPLACEMENT_INSUFFICIENT_FUNDS_ERROR',
  CARD_REPLACEMENT_INVALID_CARD_STATUS_ERROR: 'CARD_REPLACEMENT_INVALID_CARD_STATUS_ERROR',
};

// Don't change these constant!!
export const DIRECT_DEPOSIT_ACTION = {
  TERMS: 'Terms',
  CONFIRM: 'Confirm',
  ACCOUNT_INFO: 'Account Info',
};

export const DIRECT_DEPOSIT_BANK_INSTITUTION = 'Peoples Trust Company';
