import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logAmpEvent } from '../utils/Amplitude';

function ButtonContent(loading, successful, icon, text) {
  if (successful) {
    return <FontAwesomeIcon icon={['fas', 'check']} size="1x" />;
  }
  if (loading) {
    return <FontAwesomeIcon icon={['fad', 'spinner-third']} spin className="fastSpin" />;
  }
  if (icon) {
    return <FontAwesomeIcon icon={['fas', icon]} size="1x" />;
  }
  return text;
}

export default function MapleButton({
  color = 'dark', // dark | white
  size = '', // small | full
  fill = 'solid', // solid | outline
  text,
  icon,
  className = '',
  ampEvent,
  ampEventProps,
  onClickEvent,
  type = 'button',
  ariaLabel,
  loading = false,
  successful = false,
  disabled = false,
  ...props
}) {
  return (
    <button
      aria-label={ariaLabel}
      disabled={disabled || loading || successful}
      className={`mapleBtn ${color} ${size} ${fill}${disabled ? ' disable' : ''} ${className}`}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={() => {
        if (loading) return;
        logAmpEvent(ampEvent, ampEventProps);
        if (onClickEvent) {
          onClickEvent();
        }
      }}
      {...props}
    >
      { ButtonContent(loading, successful, icon, text) }
    </button>
  );
}
